import React from "react";
import {numberFormat} from "../../util/formatting/numbers";
import './cheese-slicers.scss'


const CHEESE_SLICER_PRICE = 379.99
const TESLA_PRICE = 874_990
const KITCHEN_REFURBISH_PRICE = 100_000
const PINCHCLIFFE_GRAND_PRIX_PRICE = 199

const CheeseSlicers = ({value, oilPriceNOK}) => {
    return (
        <div className='cheese-slicers'>
            <h2>eller</h2>
            <ul>
                <li>
                    {numberFormat(Math.floor(value / CHEESE_SLICER_PRICE))}<span className='postfix'>ostehøvler</span>
                </li>
                <li>
                    {numberFormat(value / TESLA_PRICE, 2)}<span className='postfix'>Tesla S</span>
                </li>
                <li>
                    <span className='prefix'>pusse opp kjøkkenet</span>
                    {numberFormat(Math.floor(value / KITCHEN_REFURBISH_PRICE))}
                    <span className='postfix'>ganger</span>
                </li>
                <li>
                    {numberFormat(Math.floor(value / PINCHCLIFFE_GRAND_PRIX_PRICE))}
                    <span className='postfix'>kopier av Flåklypa Grand Prix på Blu-Ray</span>
                </li>
                <li>
                    {numberFormat(Math.floor(value / oilPriceNOK))}
                    <span className='postfix'>oljetønner</span>
                </li>
            </ul>
        </div>
    )
}

export default CheeseSlicers